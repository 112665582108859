@import '../themes/theme.scss';


// SAC CUSTOM

// colors
$white:                 #fff;
$text-col:              #333;
$blue:                  #448aff;
$light-blue:            #39a1fc;
$light-light-blue:      #ebf3fb;
$hover-blue:            #2d78f8;
$red:                   #f05050;
$light-red:             #f05858;
$disabled-b-c:          #c4c4c4;

$fast-track:            #009981;
$vip-lounge:            #F8B000;
$vip-card:              #1E94F6;
$bgy-top:               #000;


/* FONT */
$font-tabset:           16px;

/* SECTION CONTENT */
$section-padding:       40px 100px;
$section-padding-sm:    30px 40px;

/* TYPO */
$reqfield-font-s:               14px;
$reqfield-font-w:               700;
$reqfield-font-c:               $blue;

/* CARD */ 
$card-box-shadow:               0 2px 20px 0px #e6e6e6;
$card-border-radius:            8px;
$card-title-border-radius:      8px 8px 0px 0px;
$card-bg:                       $white;
$card-spacer-y:                 40px;
$card-spacer-x:                 20px;
$card-title-margin-b:           50px;
$card-title-padding:            12px 0px;
$card-body-padding:             0px 40px 40px 40px;
$card-body-no-title-padding:    40px 40px 40px 40px;
$card-footer-padding:           20px;

/* INPUT */
$label-margin-b:         5px;
$input-border:           1px;
$input-border-color:     $blue;
$input-border-radius:    5px;
$input-padding:          0px 10px;
$input-min-height:       39px;

/* BUTTONS */
$button-align-txt:              center;
$button-margin:                 0px 10px;
$button-padding:                8px 20px;
$button-background-color:       $blue;
$button-h-background-color:     $light-blue;
$button-h-cancel-bg:          #d6e9f9;
$button-border-radius:          5px;
$button-border:                 none;
$button-text-size:              15px;
$text-color:                    $white;
$text-weight:                   700;
$button-dis-b-c:                $disabled-b-c;
$button-action-b:               1px solid $blue;
$button-action-hover-b:         1px solid $hover-blue;
$button-action-text-size:       13px;
$button-action-dis-b-c:         rgb(243, 243, 243);

/* TABLE */
$container-p:               30px 100px;
$table-w:                   100%;
$table-box-shadow:          0 2px 20px 0px #e6e6e6;
$th-text-transform:         uppercase;
$th-color:                  $white;
$th-font-size:              17px;
$th-font-weight:            700;
$th-p:                      20px 0px;
$th-bc:                     $blue;
$th-text-align:             center;
$tb-bc:                     $white;
$tb-text-align:             center;
$td-p:                      20px;
$td-color:                  $blue;
$td-font-weight:            600;
$td-font-size:              16px;
$td-img-max-height:         80px;
$td-input-max-width:        125px;

/* MODAL */
$m-content-b:               none;
$m-content-br:              10px;
$m-content-p:               30px 0px;
$m-header-p:                0px 0px;
$m-header-m:                0px 0px 20px 0px;
$m-header-b-b:              none;
$m-title-color:             $blue;
$m-title-font-s:            28px;
$m-title-font-w:            700;
$m-title-text-al:           center;
$m-title-line-h:            1;
$m-body-b:                  none;
$m-body-p:                  0px 40px;
$m-footer-b:                none;
$m-footer-p:                0px 40px;
$m-footer-m:                20px 0px 0px 0px;
$m-footer-amount-due-fw:    700;
$m-footer-amount-due-fs:    17px;


/* POPOVER */
$pop-b:                     none;
$pop-b-r:                   8px;
$pop-box-shadow:            0px 4px 20px 0px #00000029;
$pop-w:                     500px;
$pop-max-w:                 400px;
$pop-arrow-visibility:      hidden;
$pop-content-p:             20px;

/* CIRCLE COUNTER */
$circle-b-c:                $white;
$circle-b:                  2px solid $blue;
$circle-b-r:                50%;
$circle-radius:             18px;
$circle-pos:                absolute;
$circle-top:                3px;
$circle-right:              20px;
$circle-c:                  $blue;
$circle-f-size:             12px;
$circle-test-align:         center;
$circle-font-w:             700;
$circle-line-h:             1.2;

/* TABSET NAVBAR */
$tab-content-padding:       35px 30px;
$tab-content-border:        0 1px 1px 1px;
$tab-border-color:          $blue;
$tab-background-color:      $white;

/* TICKET */
$ticket-h:                  160px;
$ticket-bg-c:               $white;
$ticket-b-r:                13px;
$ticket-box-shadow:         0px 6px 10px 0px rgba(0, 0, 0, 0.16);
$ticket-text-c:             $text-col;
$ticket-b:                  1px dashed #dfdfdf;
/* TICKET CARD */
$t-card-pos:                relative;
$t-card-h:                  185px;
/* TICKET IMG */
$t-img-pos:                 absolute;
$t-img-top:                 0;
$t-img-right:               0;
$t-img-h:                   77px;
$t-img-z-i:                 100;
$t-img-b-r:                 0 $ticket-b-r 0 0;
$t-img-f-opacity-v:         20%;
$t-img-filter:              opacity($t-img-f-opacity-v);
/* QR CODE CONTAINER */
$t-qr-c-min-w:              180px;
$t-qr-c-max-w:              180px;
$t-qr-m:                    20px 0px;
$t-qr-p:                    0px 20px;
$t-qr-line-h:               1;
$t-qr-img:                  115px;
$t-qr-font-w:               700;
$t-qr-font-s:               19px;
/* DETAIL CONTAINER */
$t-det-c-clip-path:         inset(-20px -20px -20px 0px);
$t-det-c-min-w:             311px;
$t-det-c-max-w:             311px;
$t-det-m:                   20px 0px;
$t-det-p:                   0px 20px;
$t-det-line-h:              1.1;
$t-det-name-let-sp:         0.2px;
$t-det-name-col:            #959595;
$t-det-name-font-s:         13px;
$t-det-val-col:             #464040;
$t-det-val-font-w:          20px;
$t-det-val-font-s:          20px;






