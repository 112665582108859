/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/variables";
// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/user-block";
@import "app/settings";
// Common
@import "app/typo";
@import "app/bootstrap-reset";
@import "app/bootstrap-custom";
@import "app/button-extra";
@import "app/placeholder";
@import "app/cards";
@import "app/circles";
@import "app/dropdown-extra";
@import "app/half-float";
@import "app/animate";
@import "app/slim-scroll";
@import "app/inputs";
@import "app/utils";
@import "app/print";
// Elements
@import "app/spinner";
// Charts
@import "app/radial-bar";
@import "app/chart-flot";
@import "app/chart-easypie";
// Form elements
@import "app/form-select2";
@import "app/form-tags-input";
@import "app/file-upload";
@import "app/summernote";
@import "app/typeahead";
// Tables
@import "app/table-extras";
@import "app/table-angulargrid";
// Maps
@import "app/gmap";
@import "app/vector-map";
// Extras
@import "app/timeline";
@import "app/todo";
@import "app/calendar";
@import "app/mailbox";
@import "app/plans";
@import "app/sweetalert";



.selected-list .c-list .c-token {
    background: #5cbaeb !important;
    // word-break: break-all;
    max-width: 100%;
}

.selected-list .c-list .c-token .c-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

thead{
    background:#7FC4FD;
    color:#ffffff;
}
.dropdown-menu.dropdown-menu-right-forced{
    padding: 0px;
    a{
        border:1px solid #7FC4FD;
        padding:5px;
    }
}

.bs-datepicker-body table th{background:#fff;color:#000000ab !important}
.bs-datepicker .bs-calendar-container > div{
    display: flex !important;
}

.selected-list .c-angle-down svg, .selected-list .c-angle-up svg{
    fill: #4699fb !important;
}

.selected-list {
    .c-btn{
        color: #4699fb;
        border: 1px solid #7fc4fd;
    }
}

.reverse-checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

bs-dropdown-container {
    z-index: 10;
}

.multiple-row-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .input-group {
        display: block;
        width: fit-content;
        margin-left: 1rem;
    }
}

.actions-centered {
    display: flex;
    justify-content: center;
}