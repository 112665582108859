@import "sac-variables.scss";

/* SECTION CONTENT */
.section-content {
    padding: $section-padding;
    @media (max-width: 576px) {
        padding: $section-padding-sm;
    }
}

/* TYPO */
.required-field-txt {
    font-size: $reqfield-font-s;
    font-weight: $reqfield-font-w;
    color: $reqfield-font-c;
}

/* CARD */
.card,
.sac-card {
    @extend .card;
    border: 0;
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
    > .card-body {
        padding: $card-body-padding;
    }
    > .card-body-no-title {
        padding: $card-body-no-title-padding;
    }
    .card-footer {
        border: none;
    }
}
.sac-card-title {
    @extend .card-title;
    display: flex;
    justify-content: center;
    background-color: $blue;
    margin-bottom: $card-title-margin-b;
    padding: $card-title-padding;
    border-radius: $card-title-border-radius;
    color: $white;
    font-size: 18px;
    font-weight: 700;
    & img {
        max-width: 40px;
    }
}
.sac-card-footer {
    @extend .card-footer;
    padding: $card-footer-padding;
    background-color: $card-bg;
    border-top: none;
}

/* DIV */
div.fake-form-control {
    padding: 8px 10px;
    border: $input-border solid $input-border-color;
    border-radius: $input-border-radius;
}

/* INPUT */
label {
    margin-bottom: $label-margin-b;
    color: $blue;
    font-weight: 700;
}
input[type=text],
input[type=url],
input.form-control {
    padding: $input-padding;
    min-height: $input-min-height;
    border: $input-border solid $input-border-color;
    border-radius: $input-border-radius;
}

input[type=text]::placeholder,
input[type=url]::placeholder {
    color: $blue !important;
}

.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append > .input-group-text {
    @extend .input-group-text;
    background-color: $white;
    color: $blue;
    border: $input-border solid $input-border-color;
}

.myclass.custom-class input {
    border: none;
    min-height: auto;
}

/* RADIO */
.c-checkbox span:before,
.c-radio span:before {
    // @extend .c-checkbox;
    right: 1px;
}

/* MULTISELECT */
.selected-list .c-btn,
.cuppa-dropdown .selected-list .c-btn {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
}

/* BUTTONS */
button {
    text-align: $button-align-txt;
    &.btn-action {
        transition: 0.2s;
        border: $button-action-b;
        color: $white;
        background-color: $blue;
        font-size: $button-action-text-size;
        cursor: pointer;
        &:hover {
            transition: 0.2s;
            border: $button-action-hover-b;
            background-color: $hover-blue;
        }
        &:disabled {
            cursor: not-allowed;
            color: $blue;
            background-color: $button-action-dis-b-c;
            border: $button-action-b;
        }
        &:focus {
            color: $white;
            background-color: $blue;
        }
        &:active:not([disabled]) {
            color: $white;
            background-color: $blue;
        }
    }
    &.btn-cancel {
        background-color: $light-light-blue;
        border: 1px solid $blue;
        color: $blue;
        &:hover {
            cursor: pointer;
            background-color: $button-h-cancel-bg;
        }
    }
    &:disabled {
        cursor: not-allowed;
        background-color: $button-dis-b-c;
    }
}

/* TABLE */
.sac-table-container {
    padding: $container-p;
}
.sac-table {
    overflow-x: scroll;
    box-shadow: $table-box-shadow;
    width: $table-w;
    thead {
        background-color: $th-bc;
        color: $th-color;
        text-align: $th-text-align;
        th {
            text-transform: $th-text-transform;
            font-size: $th-font-size;
            font-weight: $th-font-weight;
            padding: $th-p;
        }
    }
    tbody {
        background-color: $tb-bc;
        text-align: $tb-text-align;
        tr td {
            padding: $td-p;
            color: $td-color;
            font-weight: $td-font-weight;
            font-size: $td-font-size;
            img {
                max-height: $td-img-max-height;
            }
            input.table-input {
                max-width: $td-input-max-width;
            }
        }
    }
}
thead {
    background: $secondary;
    color: $white;
}

/* MODAL */
.sac-modal {
    .modal-content {
        border: none;
        border-radius: $m-content-br;
        padding: $m-content-p;
        .modal-header {
            padding: $m-header-p;
            border: $m-header-b-b;
            margin: $m-header-m;
            .modal-title {
                color: $m-title-color;
                font-size: $m-title-font-s;
                font-weight: $m-title-font-w;
                text-align: $m-title-text-al;
                line-height: $m-title-line-h;
            }
        }
        .modal-body {
            border: $m-body-b;
            padding: $m-body-p;
        }
        .modal-footer {
            border: $m-footer-b;
            padding: $m-footer-p;
            margin: $m-footer-m;
            & .amount-due {
                font-weight: $m-footer-amount-due-fw;
                font-size: $m-footer-amount-due-fs;
            }
        }
    }
}

/* POPOVER */
.sac-popover {
    border: $pop-b;
    border-radius: $pop-b-r;
    box-shadow: $pop-box-shadow;
    width: $pop-w;
    max-width: $pop-max-w;
    .popover-arrow.arrow {
        visibility: $pop-arrow-visibility;
    }
    .popover-content.popover-body {
        padding: $pop-content-p;
    }
}

/* CIRCLE COUNTER */
.circle-counter {
    background-color: $circle-b-c;
    border: $circle-b;
    border-radius: $circle-b-r;
    width: $circle-radius;
    height: $circle-radius;
    position: $circle-pos;
    top: $circle-top;
    right: $circle-right;
    color: $circle-c;
    font-size: $circle-f-size;
    text-align: $circle-test-align;
    font-weight: $circle-font-w;
    line-height: $circle-line-h;
}

/* TICKET */
.ticket-card {
    position: $t-card-pos;
    height: $t-card-h;

    img.family-image {
        position: $t-img-pos;
        top: $t-img-top;
        right: $t-img-right;
        height: $t-img-h;
        z-index: $t-img-z-i;
        border-radius: $t-img-b-r;
        filter: $t-img-filter;
    }

    .qr-code-container {
        overflow-y: hidden;
        border-radius: $ticket-b-r;
        box-shadow: $ticket-box-shadow;
        background-color: $ticket-bg-c;
        min-width: $t-qr-c-min-w;
        max-width: $t-qr-c-max-w;

        .ticket-qr-code {
            margin: $t-qr-m;
            padding: $t-qr-p;
            line-height: $t-qr-line-h;
            border-right: $ticket-b;
    
            img {
                height: $t-qr-img;
            }
            .ticket-code {
                font-weight: $t-qr-font-w;
                font-size: $t-qr-font-s;
                &.fast-track {
                    color: $fast-track;
                }
                &.vip-lounge {
                    color: $vip-lounge;
                }
                &.vip-card {
                    color: $vip-card;
                }
                &.bgy-top {
                    color: $bgy-top;
                }
            }
        }
    }


    .detail-container {
        overflow-y: hidden;
        border-radius: $ticket-b-r;
        clip-path: $t-det-c-clip-path;
        box-shadow: $ticket-box-shadow;
        background-color: $ticket-bg-c;
        min-width: $t-det-c-min-w;
        max-width: $t-det-c-max-w;
        
        .ticket-detail {
            margin: $t-det-m;
            padding: $t-det-p;
            border-left: $ticket-b;
            .product-detail {
                line-height: $t-det-line-h;
                .detail-name {
                    letter-spacing: $t-det-name-let-sp;
                    color: $t-det-name-col;
                    font-size: $t-det-name-font-s;
                }
                .detail-value {
                    color: $t-det-val-col;
                    font-weight: $t-det-val-font-w;
                    font-size: $t-det-val-font-s;
                }
            }
        }
    }

}

/* TABSET NAVBAR */
/* .tab-container {
    ul.nav.nav-tabs {
        border-bottom: 1px solid $blue;
        a {
            font-size: $font-tabset;
            font-weight: 700;
            border: 0px;
        }
        > li.nav-item.active {
            border: 1px solid $blue;
            a {
                background-color: $light-light-blue;
                color: $blue;
            }
        }
    }
    .tab-content {
        background-color: $tab-background-color;
        padding: $tab-content-padding;
        border-style: solid;
        border-width: $tab-content-border;
        border-color: $tab-border-color;
    }
} */

@media (max-width: 767px) {

    .sac-table-container {
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .sac-table {
            display: flex;

            thead {
                display: flex;
                flex-flow: column;
                justify-content: center;

                th {
                    margin: 0 1.6rem;
                }
            }

            tbody {
                width: 100%;

                tr {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
