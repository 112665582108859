/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
@import '~@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
@import '~@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~weather-icons/css/weather-icons.css';
@import '~weather-icons/css/weather-icons-wind.css';

@import '~spinkit/spinkit.css';
@import '~loaders.css/loaders.css';

@import '~ag-grid/dist/styles/ag-grid.css';
@import '~ag-grid/dist/styles/theme-fresh.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~jqcloud2/dist/jqcloud.css';

@import '~summernote/dist/summernote.css';


//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//== Custom
@import "./app/shared/styles/custom/sac-custom-class.scss";

//== Summernote FIX
.note-popover.popover.in.note-table-popover.bottom,
.note-popover.popover.in.note-image-popover.bottom,
.note-popover.popover.in.note-link-popover.bottom {
  display: none;
}

.image-preview {
  max-width: 100%;
  max-height: 100px;
}

// Table column additional information
.table-column-info {
  font-size: 0.7rem;
  
  .t-code {
    font-size: 0.6rem;
  }
}

.error-msg {
  font-weight: bold;
  color: red;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

span.tag {
  text-transform: uppercase;
  border-radius: 7px;
  font-weight: 600;
  padding: 5px;
  width: max-content;
  min-width: 80px;
  display: inline-block;
  text-align: center;
}

// swal confirm buttons color
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: $info !important;
}

// angular2-multiselect without slow animation
angular2-multiselect > div > div.dropdown-list {
  animation-name: none;
}

.sk-circle {
  margin: 0;
}

.card-container {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.single-card {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
  button:hover {
      background: transparent;
  }
}

.resized-modal {
  max-width: 45rem;
}

.sac-stepper {
  min-width: 90px;
}

.c-btn.disabled {
  background-color: #edf1f2 !important;
  cursor: not-allowed !important;
}

.c-label.ng-star-inserted {
  color: #FFFFFF;
}

.sac-checkbox-button, .sac-checkbox-button__input {
  cursor: pointer;
}

.sac-status {
  color: #ffffff;

  span {
      text-transform: uppercase;
      border-radius: 7px;
      font-weight: 600;
      padding: 5px;
      width: max-content;
      min-width: 80px;
      display: inline-block;
      text-align: center;
  }
  .bg-default{
      background-color: darkgray !important;
  }
  .bg-info {
      cursor: pointer;
      background-color: #4699fb !important;
  }
  .bg-secondary {
      background-color: #333333 !important;
  }
}
